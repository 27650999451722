@import-normalize;

html, body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100%;
}

body {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: white;
    background: black;
}

button {
    all: unset;
    font-family: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
}

button:focus {
    outline: none;
    box-shadow: none;
}

a {
    all: unset;
    color: inherit;
    cursor: pointer;
}
a:visited {
    color: inherit;
}
a:focus {
    outline: none;
}

ul {
    all: unset;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

input[type=text], input[type=password] {
    all: unset;
    text-align: left;
}

* {
    scrollbar-color: #9DCCFF #162A4A;
    box-sizing: border-box;
}

*::-webkit-scrollbar-track {
    background: #162A4A;
}

*::-webkit-scrollbar-thumb {
    background: #9DCCFF;
}

.action {
    position: relative;
    display: inline-block;
    border-radius: 30px;
    box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.6);
}

.action:before {
    content: "";
    border-radius: inherit;
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    background: linear-gradient(170deg, #5e6b90 0%, #5e6b9000 37%);
    margin: -1px;
}

.action button, .action a {
    position: relative;
    border-radius: inherit;
    background: linear-gradient(101.12deg, #3A476F 0%, #2C3656 100%);
    color: white;
    font-size: 25px;
    padding: 7px 67px 9px 67px;
    border: none;
    cursor: pointer;
}

.action button:active, .action a:active {
    background: linear-gradient(101.12deg, #617CA5 0%, #34578A 100%);
}

.action button:disabled, .action a.disabled {
    color: #ffffff80;
    cursor: auto;
    background: linear-gradient(101.12deg, #3A476F 0%, #2C3656 100%);
}