.modes {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    justify-content: right;
    align-content: end;
    grid-gap: 34px;
    padding: 0 50px 0 0;
}

.modes button {
    color: white;
    font-weight: 300;
    font-size: 18px;
    padding: 16px 16px;
    cursor: pointer;
    position: relative;
}

.modes button:after {
    display: block;
    content: attr(data-text);
    font-weight: bold;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
}

.modes button.active {
    font-weight: bold;
    color: #9DCCFF;
}

