.chart :global(.tick) {
    font-family: Roboto, sans-serif;
    color: #c5c5c5;
}

.chart :global(.xAxis) {
    font-size: 12px;
}

.chart :global(.yAxis) {
    font-size: 12px;
}

.chart :global(.underArea) {
    fill: url(#area_underFill);
}

.chart :global(.overArea) {
    fill: url(#area_overFill);
}

.chart :global(.line) {
    stroke: url(#area_lineStroke);
    stroke-width: 5px;
    fill: none;
    stroke-linecap: square;
}