.chart {
    padding: 0 0 12px 0;
    font-family: Roboto, sans-serif;
    margin-top: -15px;
}

.averageValue {
    font-size: 96px;
    font-weight: 700;
    fill: #2CF5EE;
}

.targetValue {
    font-size: 36px;
    font-weight: 700;
    fill: #2CF5EE;
}

.label {
    font-size: 26px;
    font-weight: 400;
    fill: white;
}