.title {
    padding: 16px 16px 16px 11px;
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.timeList {
    padding: 0 0 6px 0;
}

.timeList button {
    font-weight: 400;
    font-size: 13px;
    min-width: 128px;
    text-align: left;
    cursor: pointer;
    padding: 11px 12px;
    white-space: nowrap;
    display: block;
}

.timeList button.active {
    text-decoration: underline;
}
