.dropDown {
    display: inline;
}

.root {
}

.backdrop {
    position: fixed;
    z-index: 996;
    left: 0; right: 0; top: 0; bottom: 0;
}

.anchor {
    position: fixed;
    z-index: 997;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.container {
    position: relative;
    z-index: 998;
    box-shadow: 20px 20px 20px rgba(2, 12, 44, 0.5);
    border-radius: 10px;
}

.container:before {
    content: "";
    border-radius: inherit;
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    background: linear-gradient(155deg, #566278 0%, #56627800 37%);
    margin: -1px;
}

.content {
    position: relative;
    z-index: 999;
    border-radius: inherit;
    background: linear-gradient(101.12deg, rgba(14, 28, 46, 1) 0%, rgba(7, 21, 41, 1) 100%);
}

.dateRange {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto;
    justify-content: end;
    grid-gap: 8px;
}

.dateRange > div {
    padding: 16px 0;
    white-space: nowrap;
}
.dateRange > div:nth-child(1), .dateRange > div:nth-child(3) {
    padding: 16px;
    text-decoration: underline;
    cursor: pointer;
}
.dateRange > div:nth-child(2) {
    font-weight: 300;
    color: #c5c5c5;
}
