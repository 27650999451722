.chart {
    padding: 15px 0 30px 0;
}

.valueText {
    font-family: Roboto, sans-serif;
    font-size: 66px;
    font-weight: 700;
    fill: #00a699;
}

.titleText {
    font-family: Roboto, sans-serif;
    font-size: 48px;
    font-weight: 700;
    fill: #00a699;
}