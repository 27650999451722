.title {
    padding: 16px 16px 16px 11px;
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.aggregationList {
    padding: 0 0 6px 0;
}

.aggregationList button {
    font-weight: 400;
    font-size: 13px;
    min-width: 128px;
    text-align: left;
    cursor: pointer;
    padding: 11px 12px;
    white-space: nowrap;
    display: block;
}

.aggregationList button.active {
    text-decoration: underline;
}

.aggregationList button.indent {
    padding-left: 18px;
}

.withLocation {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto minmax(max-content, 176px);
}

.withLocation > div {
    display: inline-block;
    position: relative;
    padding: 0 16px;
}

.withLocation > div:nth-child(1):after {
    content: "";
    position: absolute;
    width: 1px;
    top: 0; bottom: -6px; right: 0;
    background: #898989;
}

.withLocation > div:nth-child(2) {
    max-height: 176px;
    overflow-x: hidden;
    overflow-y: auto;
}