.loader {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 64px;
    height: 64px;
}
.loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 56px;
    height: 56px;
    margin: 8px;
    border: 5px solid #fff;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #c5c5c5 transparent transparent transparent;
}
.loader div:nth-child(1) {
    animation-delay: -0.45s;
}
.loader div:nth-child(2) {
    animation-delay: -0.3s;
}
.loader div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}