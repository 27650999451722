.header {
    padding: 0 4px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto minmax(0, max-content) 1fr auto;
    justify-content: start;
    font-size: 13px;
}

.header div:nth-child(3), .header div:nth-child(4) {
    justify-self: end;
}

.icon {
    background-position: center;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.icon-chart {
    background-image: url("../images/chart-icon.svg");
    background-size: 11px 15px;
    width: 46px;
    height: 46px;
}

.icon-close {
    background-image: url("../images/close-icon.svg");
    background-size: 14px 14px;
    width: 46px;
    height: 46px;
}

.date {
    padding: 16px 20px 16px 16px;
    justify-self: end;
    text-decoration: underline;
    cursor: pointer;
}

.maximize {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../images/maximize-icon.svg");
    background-size: 13px 13px;
    width: 45px;
    height: 45px;
    cursor: pointer;
    justify-self: end;
}

.content {
    width: 100%;
    height: 100%;
}

.hidden {
    display: none;
}