.typeSelector {
    height: 100%;
    padding-bottom: 48px;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    grid-gap: 85px;
}

.typeSelector > button {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 104px;
    height: 101px;
}

.bar, .stackedBar {
    background-image: url("../images/chart-type-bar.svg");
}
.area_smooth {
    background-image: url("../images/chart-type-smooth-line.svg");
}
.area {
    background-image: url("../images/chart-type-line.svg");
}
