.dropDown {
    display: inline;
}

.root {
}

.anchor {
    position: relative;
}

.container {
    position: absolute;
    z-index: 999;
    box-shadow: 5px 5px 40px rgba(2, 12, 44, 0.82);
    border-radius: 10px;
}

.container:before {
    content: "";
    border-radius: inherit;
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    background: linear-gradient(155deg, #566278 0%, #56627800 37%);
    margin: -1px;
}

.content {
    position: relative;
    border-radius: inherit;
    background: linear-gradient(101.12deg, rgba(14, 28, 46, 1) 0%, rgba(7, 21, 41, 1) 100%);
}