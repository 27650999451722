.layout {
    height: 100%;
    padding: 21px 24px 43px 35px;
    display: grid;
    grid-gap: 28px;
}

.threePanel {
    grid-template-columns: 1fr 0.46fr;
    grid-template-rows: 1fr 1fr;
}

.threePanel > div:nth-child(1) {
    grid-area: 1 / 1;
}
.threePanel > div:nth-child(2) {
    grid-area: 1 / 2;
}
.threePanel > div:nth-child(3) {
    grid-area: 2 / 1 / span 1 / span 2;
}

.onePanel {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.onePanel > div {
    grid-area: 1 / 1;
}
