.list {
    padding: 0 21px 17px 16px;
    font-size: 18px;
}

.list div {
    position: relative;
    padding: 12px 48px 12px 0;
    cursor: pointer;
}

.list div:after {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 25px;
    content: "+";
}

.list.expanded div:after {
    content: "−";
}

.list li {
    position: relative;
    font-weight: 300;
    font-size: 16px;
    display: block;
    cursor: pointer;
    padding: 12px 48px 12px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list li.indent {
    padding-left: 16px;
}

.list li:after {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 25px;
    content: "";
    background-image: url("../images/radio-off.svg");
    background-size: 100% 100%;
    width: 10px;
    height: 10px;
}

.list li.active:after {
    background-image: url("../images/radio-on.svg");
}