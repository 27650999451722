.page {
    display: grid;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100vh;
}

.page form {
    width: 336px;
    text-align: center;
}

.error {
    color: red;
}

.header {
    margin-bottom: 22px;
}

.logo {
    width: auto;
    height: 70px;
}

.field {
    margin: 0 auto 27px auto;
}

.field input[type=text], .field input[type=password] {
    background: none;
    border: none;
    color: white;
    font-size: 25px;
    border-bottom: 1px solid white;
    padding: 0 0 4px 0;
    outline: none;
    border-radius: 0;
}

.field input:focus {
    outline: none;
}

.field input::placeholder {
    color: #bcbcbc;
    opacity: 0.9;
}

.actions {
    margin: 60px auto 0 auto;
}

.video {
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video video {
    height: 100%;
    width: auto;
}

.enter.form {
    opacity: 0;
}
.enterActive.form {
    transition: opacity 1s linear;
    opacity: 1;
}

.fontPreload {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}