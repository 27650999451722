.outer {
    position: relative;
    width: 100%;
    height: 100%;
}

.inner {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
}
