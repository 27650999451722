.page {
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr;
    background: linear-gradient(119.63deg, #1e324f 2.35%, #07284f 93.43%);
}

.header {
    padding: 0 4px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto minmax(0, max-content) 1fr auto;
    justify-content: start;
    font-size: 13px;
    width: 93%;
    margin: 0 auto;
}

.icon {
    background-position: center;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.icon-table {
    background-image: url("../images/table.svg");
    filter: invert(1);
    width: 46px;
    height: 46px;
}

.icon-close {
    background-image: url("../images/close-icon.svg");
    background-size: 14px 14px;
    width: 46px;
    height: 46px;
}

.title {
    padding: 16px 16px 16px 11px;
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.aggregationList {
    padding: 0 0 6px 0;
}

.aggregationList button {
    font-weight: 400;
    font-size: 13px;
    min-width: 128px;
    text-align: left;
    cursor: pointer;
    padding: 11px 12px;
    white-space: nowrap;
    display: block;
}

.aggregationList button.active {
    text-decoration: underline;
}

.aggregationList button.indent {
    padding-left: 18px;
}
.table {
    display: flex;
    flex-direction: column;
    height: 95%;
}

.table table {
    width: 90%;
    align-self: center;
}

.backdrop {
    position: fixed;
    z-index: 996;
}

.anchor {
    position: fixed;
    z-index: 997;
}

.container {
    position: relative;
    z-index: 998;
    box-shadow: 20px 20px 20px rgba(2, 12, 44, 0.5);
    border-radius: 10px;
}

.container:before {
    content: "";
    border-radius: inherit;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(155deg, #566278 0%, #56627800 37%);
    margin: -1px;
}

.content {
    position: relative;
    z-index: 999;
    border-radius: inherit;
    background: linear-gradient(
        101.12deg,
        rgba(14, 28, 46, 1) 0%,
        rgba(7, 21, 41, 1) 100%
    );
}
.choice {
    padding: 16px 16px 16px 11px;
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.icon_battery_100 {
    background-image: url("../images/battery/battery-100.svg");
    width: 46px;
    height: 46px;
}
.icon-battery-50 {
    background-image: url("../images/battery/battery-50.svg");
    width: 46px;
    height: 46px;
}
.icon-battery-20 {
    background-image: url("../images/battery/battery-20.svg");
    width: 46px;
    height: 46px;
}
.icon-battery-10 {
    background-image: url("../images/battery/battery-10.svg");
    width: 46px;
    height: 46px;
}
.icon-battery-5 {
    background-image: url("../images/battery/battery-5.svg");
    width: 46px;
    height: 46px;
}
