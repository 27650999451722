.header {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto auto auto 1fr auto;
}

.settingsButton {
    display: inline-block;
    background: url("../images/settings-icon.svg") no-repeat center;
    background-size: 19px;
    width: 69px;
    height: 64px;
    cursor: pointer;
}

.logo {
    height: 29px;
    width: auto;
    margin-top: 18px;
}

.env {
    padding-left: 15px;
    display: inline-block;
    align-self: center;
}