.chart :global(.tick) {
    font-family: Roboto, sans-serif;
    color: #c5c5c5;
}

.chart :global(.xAxis) {
    font-size: 12px;
}

.chart :global(.yAxis) {
    font-size: 12px;
}