.container {
    display: none;
    position: fixed;
    z-index: 999;
    box-shadow: 5px 5px 40px rgba(2, 12, 44, 0.82);
    border-radius: 10px;
    max-width: 150px;
    transform: translateX(-50%);
}

.container:before {
    content: "";
    border-radius: inherit;
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    background: linear-gradient(155deg, #566278 0%, #56627800 37%);
    margin: -1px;
}

.content {
    position: relative;
    border-radius: inherit;
    background: linear-gradient(101.12deg, rgba(14, 28, 46, 1) 0%, rgba(7, 21, 41, 1) 100%);
    padding: 10px 15px 19px 15px;
}

.header {
    font-size: 10px;
    color: #9DCCFF;
    margin-bottom: 10px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.metrics {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    grid-row-gap: 11px;
    font-size: 9px;
}

.metrics div:nth-child(odd) {
    padding-right: 8px;
    grid-column: 1;
}

.metrics div:nth-child(even) {
    justify-self: right;
    grid-column: 2;
}

.alert {
    color: #EB5D55;
    font-weight: 900;
}