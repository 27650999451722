.backdrop {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 898;
    background: rgba(15, 43, 79, 0.7);
    backdrop-filter: blur(10px);
}

.container {
    position: absolute;
    left: 0; bottom: 0;
    top: 5px;
    width: 293px;
    z-index: 899;
    background: linear-gradient(101.12deg, rgba(14, 28, 46, 0.8) 0%, rgba(7, 21, 41, 0.8) 100%);
    box-shadow: 40px 40px 40px rgba(2, 12, 44, 0.5);
    backdrop-filter: blur(24px);
}

.container:before {
    position: absolute;
    content: "";
    left: 0; right: 0; top: -1px;
    height: 1px;
    background: linear-gradient(to right, #57667C 0%, #57667c00 80%);
}

.content {
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
}

.header {
    font-size: 18px;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    padding: 14px 21px 17px 14px;
}

.header div {
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-gap: 35px;
}

.header button {
    font-weight: 300;
    padding: 8px;
    margin: -8px -8px 0 0;
    justify-self: end;
}

.lists {
    overflow-y: auto;
}

.enter.container {
    transform: translate(-293px, 0);
}
.enterActive.container {
    transition: transform 0.25s ease-out;
    transform: translate(0, 0);
}
.exitActive.container {
    transition: transform 0.25s ease-out;
    transform: translate(-293px, 0);
}

.enter.backdrop {
    opacity: 0;
}
.enterActive.backdrop {
    transition: opacity 0.25s linear;
    opacity: 1;
}
.exitActive.backdrop {
    transition: opacity 0.25s linear;
    opacity: 0;
}