.panelOuter {
    position: relative;
    border-radius: 29px;
    box-shadow: 3px 3px 25px rgba(0, 0, 0, 0.6);
}

.hidden {
    display: none;
}

.panelOuter:before {
    content: "";
    border-radius: inherit;
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    background: linear-gradient(150deg, #546376 0%, #54637600 60%);
    opacity: 0.9;
    margin: -1px;
}

.panelInner {
    position: relative;
    border-radius: inherit;
    background: linear-gradient(101.12deg, #1D314F 0%, #102749 100%);
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr;
}